import React from 'react';
import './Kits.scss';


function ToomarTextarea({title, inputValue, onChange, placeholder, disabled}) {
    return (
        <div className={'toomarInputContainer'}>
            <p>
                {title}
            </p>
            <textarea
            value={inputValue}
            onChange={(e) => onChange(e)}
            placeholder={placeholder}
            disabled={disabled}
            rows={7}
            />
        </div>
    )
}


export default ToomarTextarea;