import React, { useEffect, useState } from "react";
import ToomarContainer from "../containers/ToomarContainer";
import Title from "../Utils/Title";
import "./RepetitiveQuestions.scss";
import FollowUsInSocials from "../kit/FollowUsInSocials";
import CollapseBar from "../Utils/CollapseBar";
import { getQuestions } from "../../redux/actions";
import { connect } from "react-redux";

function RepetitiveQuestions({ ...props }) {
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    props.getQuestions((data) => setQuestions(data.data.result));
  }, {});
  return (
    <ToomarContainer>
      <Title goldText={"Repetitive"} blackText={"Questions"} />
      <div className={"repetitiveQuestionsContainerPage"}>
        {questions.map((question, index) => (
          <CollapseBar question={question.question} answer={question.answer} />
        ))}
        <FollowUsInSocials />
      </div>
    </ToomarContainer>
  );
}

export default connect(null, { getQuestions })(RepetitiveQuestions);
