import React, { useEffect, useState } from "react";
import ToomarContainer from "../containers/ToomarContainer";
import "./FollowUpOrder.scss";
import FollowupDrawing from "../../assets/followupDrawing.svg";
import FollowupSending from "../../assets/followupSending.svg";
import FollowupFailed from "../../assets/followupFailed.svg";
import FollowUpProcess from "../../assets/followUpProcess.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllFeatures from "../kit/AllFeatures";
import { followupOrderStatus } from "../../redux/actions";
import { connect } from "react-redux";
import FollowUpOrderItem from "./FollowUpOrderItem";
import Tick from "../../assets/tick.svg";
import {
  dayName,
  differenceInDays,
  handleDifferenceForStatus,
} from "../Utils/DatesDifference";
import moment from "jalali-moment";

function FollowUpOrder({ ...props }) {
  const [trackingCode, setTrackingCode] = useState("");
  const [submitPressed, setSubmitPressed] = useState(false);
  const [statusData, setStatusData] = useState({});
  const [apiData, setApiData] = useState();
  const [orderDate, setOrderDate] = useState(new Date("01/16/2024"));
  const handleSubmitPressed = () => {
    if (trackingCode !== "") {
      props.followupOrderStatus(
        trackingCode,
        (data) => {
          if (data.data.status == "success") {
            setSubmitPressed(true);
            setStatusData(data.data.result);
            setOrderDate(new Date(data.data.created_at));
            setApiData(data.data);
          } else {
            toast.error("کد رهگیری نامعتبر است");
          }
        },
        () => toast.error("کد رهگیری نامعتبر است")
      );
    } else toast.error("کد رهگیری را وارد کنید");
  };

  useEffect(() => {
    // const jalaliOrderDate = moment(orderDate).format("jYYYY/jM/jD");
    // const jalaliTodayDate = moment(new Date()).format("jYYYY/jM/jD");
    let localStatus = handleDifferenceForStatus(
      moment(orderDate).format("DD/MM/YYYY"),
      moment(new Date()).format("DD/MM/YYYY"),
      dayName(orderDate),
      dayName(new Date()),
      differenceInDays(orderDate, new Date())
    );
    if (statusData < localStatus && statusData !== 0) {
      setStatusData(localStatus);
    }
  }, [statusData, orderDate]);

  return (
    <ToomarContainer>
      <ToastContainer />
      {submitPressed ? (
        <>
          <div className={"progressBarContainerForFollowUpOrder"}>
            <div>
              {/* <div style={statusData >= 1 ? {backgroundColor: "green"} : null}/>
                            <div style={statusData >= 1 ? {backgroundColor: "green"} : null}/> */}
              <div
                style={
                  statusData >= 2
                    ? { backgroundColor: "green" }
                    : statusData === 1
                    ? { backgroundColor: "#D45A07" }
                    : null
                }
              >
                <img src={Tick} alt={"tick"} />
              </div>
              <div
                style={statusData >= 2 ? { backgroundColor: "green" } : null}
              />
              <div
                style={
                  statusData >= 3
                    ? { backgroundColor: "green" }
                    : statusData === 2
                    ? { backgroundColor: "#D45A07" }
                    : null
                }
              >
                <img src={Tick} alt={"tick"} />
              </div>
              <div
                style={statusData >= 3 ? { backgroundColor: "green" } : null}
              />
              <div
                style={
                  statusData >= 3
                    ? { backgroundColor: "green" }
                    : statusData === 3
                    ? { backgroundColor: "#D45A07" }
                    : null
                }
              >
                <img src={Tick} alt={"tick"} />
              </div>
            </div>
            <div>
              {/* <p>
                            ناموفق
                            </p> */}
              <p>Processing</p>
              <p>Designing</p>
              <p>Packaging and sending</p>
            </div>
          </div>
          <div className={"followUpItemsContainer"}>
            <p>Cart</p>
            {apiData &&
              apiData.products.map((item) => <FollowUpOrderItem data={item} />)}
            <p style={{ border: "none", paddingBottom: 0 }}>
              Customer information
            </p>
            <div>
              {apiData && (
                <>
                  <div>
                    <p>Name:</p>
                    <p>{apiData.buyer.name}</p>
                  </div>
                  <div>
                    <p>Phone number:</p>
                    <p>{apiData.buyer.phone_number}</p>
                  </div>
                  {apiData.buyer.address != "" && (
                    <div>
                      <p>Address:</p>
                      <p>{apiData.buyer.address}</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="followUpDetails">
            <p>Dear user</p>
            <p>
              Your order has been submitted successfully ، Thanks for your trust
              🙏
            </p>
          </div>
          <div className={"followUpOrderContainer"}>
            <div>
              <img
                src={
                  statusData == 1
                    ? FollowUpProcess
                    : statusData == 2
                    ? FollowupDrawing
                    : statusData == 0
                    ? FollowupFailed
                    : FollowupSending
                }
                alt={"state"}
              />
              <p>
                Dear Customer your order is in
                <span
                  style={
                    statusData == 0
                      ? { color: "red" }
                      : statusData == 3
                      ? { color: "green" }
                      : { color: "orange" }
                  }
                >
                  {statusData == 0
                    ? " Unsuccessful "
                    : statusData == 1
                    ? " Processing "
                    : statusData == 2
                    ? " Designing "
                    : " Packaging and sending "}
                </span>
                status.
              </p>
              <p>Your tracking code is</p>
              <p>{trackingCode}</p>
            </div>
          </div>
          <div className="followUpDetails" style={{ marginBottom: 40 }}>
            <p>notice that ;</p>
            <p>
              <img src={require("../../assets/redLink.png")} />
              The production process for our products takes between 4 and 7
              business days, depending on the material and type of product. Once
              your product is ready, it will be packaged and shipped via
              international mail. Delivery time varies depending on your country
              and location, but typically takes between 10 and 20 business days.
            </p>
            <p>
              <img src={require("../../assets/redLink.png")} />
              In all printed orders, such as printed and digital wedding cards,
              before printing and finalizing the design, a photo of the designed
              text (on Telegram, WhatsApp or other messengers) will be sent to
              you for confirmation.
            </p>
            <p>
              <img src={require("../../assets/redLink.png")} />
              Digital clip or photo wedding card orders are made within 48
              working hours.
            </p>
            <p>
              <img src={require("../../assets/redLink.png")} />
              If you need to send a photo file, song or other additional
              information for your order, wait for the design team to send you a
              message on Messenger (Telegram, WhatsApp, etc.) within 48 hours
              after placing the order for additional information. be received
              from you.
            </p>
          </div>
          {/* <AllFeatures/> */}
        </>
      ) : (
        <div className={"enterTrackingCodeContainer"}>
          <input
            placeholder="Write your tracking order code"
            value={trackingCode}
            onChange={(e) => setTrackingCode(e.target.value)}
          />
          <button onClick={handleSubmitPressed}>Tracking</button>
        </div>
      )}
    </ToomarContainer>
  );
}

export default connect(null, { followupOrderStatus })(FollowUpOrder);
