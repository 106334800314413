import React from 'react';
import './Kits.scss';


function ToomarInput({title, inputValue, onChange, placeholder, disabled, maxLength, isNumber, errorMessage}) {
    return (
        <div className={'toomarInputContainer'}>
            <p>
                {title}
            </p>
            <input
            maxLength={maxLength}
            value={inputValue}
            onChange={(e) => onChange(e)}
            placeholder={placeholder}
            disabled={disabled}
            type={isNumber == true ? 'number' : 'text'}
            />
            {errorMessage && 
            <p>
                {errorMessage}
            </p>
            }
        </div>
    )
}


export default ToomarInput;