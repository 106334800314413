import React, { useEffect, useRef, useState } from "react";
import Filter from "../../assets/filter.svg";
import grayFilter from "../../assets/grayFilter.svg";
import Logo from "../../assets/toomarLogo.svg";
import Search from "../../assets/search.svg";
import Bag from "../../assets/bag.svg";
import Telegram from "../../assets/telegram.svg";
import Whatsapp from "../../assets/whatsapp.svg";
import Instagram from "../../assets/instagram.svg";
import Hamburger from "../../assets/hamburger.svg";
import Cart from "../../assets/cart.svg";
import Close from "../../assets/circleClose.svg";
import "./Menu.scss";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { getCategoriesTree, getSearchResults } from "../../redux/actions";
import ToomarCart from "../Utils/ToomarCart";
import WeddingDigitalCard from "../../assets/weddingDigitalCard.svg";
import SpecialGift from "../../assets/specialGift.svg";
import WeddingGift from "../../assets/weddingGift.svg";
import WeddingCardBlack from "../../assets/weddingCardBlack.svg";
import Office from "../../assets/office.svg";
import MemorialBoard from "../../assets/memorialBoard.svg";
import { categoryUrl } from "../../lib/urls";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "500px",
    // height: 'var(--dialog-height)',
    borderRadius: "24px",
    "@media (max-width:800px)": {
      width: "100%",
      margin: "0px !important",
    },
  },
}));

function Menu(props) {
  const [openMenu, setOpenMenu] = useState(false);
  const [cartModal, setCartModal] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openMegaMenu, setOpenMegaMenu] = useState(false);
  const menuRef = useRef();
  const mobileMenuRef = useRef();
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchResults] = useState([]);
  const [searchedCategories, setSearchCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [firstChildCategory, setFirstChildCategory] = useState({});
  const [showCategories, setShowCategories] = useState(false);
  const [inApi, setInApi] = useState(false);
  const [showLanguageBox, setShowLanguageBox] = useState(false);
  const navigate = useNavigate();

  const handleGetResults = async (search) => {
    if (search.target.value == "") {
      setSearchText("");
      setSearchResults([]);
      setInApi(false);
    } else {
      setInApi(true);
      setSearchText(search.target.value.toUpperCase());
      props.getSearchResults(search.target.value.toUpperCase(), (data) => {
        setSearchResults(data.data.by_code);
        setSearchCategories(data.data.by_category);
      });
      setInApi(false);
    }
  };

  useEffect(() => {
    mobileMenuRef.current.scrollIntoView({ behavior: "smooth" });
    props.getCategoriesTree("domain=2", (data) =>
      setAllCategories(data.data.result.children)
    );
  }, [mobileMenuRef, props]);

  return (
    <>
      <Dialog
        open={cartModal}
        hideBackdrop={false}
        onClose={() => setCartModal(false)}
        PaperProps={{
          className: classes.root,
        }}
      >
        <div className={"noBuyingProductContainer"}>
          <img src={Cart} />
          <p>Your shopping cart is empty</p>
          <p>Visit the product gallery</p>
          <Link to={"/gallery/"}>
            <button>Go to product gallery</button>
          </Link>
        </div>
      </Dialog>
      <div ref={menuRef} className={"menuContainer"}>
        <div style={{ direction: "rtl" }}>
          <a
            style={{ display: "flex" }}
            href={"http://t.me/toomar_aroosicard"}
            target={"_blank"}
          >
            <img src={Telegram} alt={"telegram"} />
          </a>
          <a
            style={{ display: "flex" }}
            href={"http://wa.me/989057837887"}
            target={"_blank"}
          >
            <img src={Whatsapp} alt={"whatsapp"} />
          </a>
          <a
            style={{ display: "flex" }}
            href={"https://www.instagram.com/toomar_aroosicard/"}
            target={"_blank"}
          >
            <img src={Instagram} alt={"instagram"} />
          </a>
          <div
            style={{ position: "relative" }}
            onClick={() => setShowLanguageBox(!showLanguageBox)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: "4px 0px",
                border: "1px solid black",
                borderRadius: "14px",
                marginRight: "40px",
                direction: "ltr",
                minWidth: "130px",
                cursor: "pointer",
              }}
            >
              <img
                src={require("../../assets/usa.png")}
                alt="usa"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
              <p
                style={{
                  fontSize: "14px",
                  color: "black",
                  margin: "0px 0px 0px 6px",
                }}
              >
                EN
              </p>
              <p
                style={{
                  fontSize: "12px",
                  color: "rgba(0, 0, 0, 0.7)",
                  margin: "0px 0px 0px 2px",
                }}
              >
                {"(USD)"}
              </p>
            </div>
            {showLanguageBox && (
              <div
                style={{ position: "absolute", bottom: "-40px", left: "0px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "4px 0px",
                    border: "1px solid black",
                    borderRadius: "14px",
                    marginRight: "40px",
                    direction: "ltr",
                    minWidth: "130px",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open("https://toomar.co")}
                >
                  <img
                    src={require("../../assets/iran.png")}
                    alt="iran"
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      color: "black",
                      margin: "0px 0px 0px 6px",
                    }}
                  >
                    Persian
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "rgba(0, 0, 0, 0.7)",
                      margin: "0px 0px 0px 2px",
                    }}
                  >
                    {"(RIAL)"}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ direction: "ltr" }}>
          <div>
            <Link to={"/"}>
              <img src={Logo} alt={"logo"} />
            </Link>
            <div
              onMouseOver={() => setOpenMegaMenu(true)}
              onMouseLeave={() => {
                setOpenMegaMenu(false);
                setFirstChildCategory({});
              }}
            >
              <img src={Filter} alt={"filter"} />
              <span>Category</span>
              {openMegaMenu ? (
                <div>
                  <div>
                    <div>
                      <img src={grayFilter} />
                      <span>Products Categories</span>
                    </div>
                    {allCategories.map((item, index) => (
                      <div
                        onClick={() => {
                          navigate(categoryUrl(item));
                        }}
                        className={
                          firstChildCategory.name == item.name ? "active" : null
                        }
                        onMouseOver={() => setFirstChildCategory(item)}
                      >
                        {item.icon ? (
                          <img
                            style={{ width: 24, height: 24 }}
                            src={`https://toomar.co${item.icon}`}
                          />
                        ) : (
                          <img
                            src={
                              index == 0
                                ? WeddingCardBlack
                                : index == 1
                                ? WeddingDigitalCard
                                : index == 2
                                ? WeddingGift
                                : index == 3
                                ? Office
                                : index == 4
                                ? MemorialBoard
                                : SpecialGift
                            }
                          />
                        )}
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                  {firstChildCategory.name != undefined ? (
                    <div>
                      <span>{`All ${firstChildCategory.name} categories`}</span>
                      {firstChildCategory.children.length > 0 &&
                        firstChildCategory.children.map((item) => (
                          <div>
                            <div
                              onClick={() => {
                                navigate(categoryUrl(item));
                              }}
                            >
                              <p>.</p>
                              <p>{item.name}</p>
                            </div>
                            {item.children.length > 0 ? (
                              <div>
                                {item.children.map((item) => (
                                  <p
                                    onClick={() => {
                                      navigate(categoryUrl(item));
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        ))}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <Link to={"/gallery/"}>
              <div>
                <span>Wedding Products Gallery</span>
              </div>
            </Link>
            {/* <a href={"https://tarhim.toomar.co/"}>
                            <div>
                                <span>
                                    ورود به سایت ترحیم
                                </span>
                            </div>
                        </a> */}
            <Link to={"/magazine/"}>
              <div>
                <span>Toomar Mag</span>
              </div>
            </Link>
            <Link to="/about-us/">
              <div>
                <span>About us</span>
              </div>
            </Link>
            <Link to="/contact-us/">
              <div>
                <span>Contact us</span>
              </div>
            </Link>
            <Link to="/followup-order/">
              <div>
                <span>Order Tracking</span>
              </div>
            </Link>
          </div>
          <div>
            {openSearch ? (
              <div className="searchContainer">
                <input
                  placeholder="enter product code or desired category"
                  value={searchText}
                  onChange={(e) => handleGetResults(e)}
                />
                <img
                  onClick={() => {
                    setSearchResults([]);
                    setSearchText("");
                    setOpenSearch(false);
                  }}
                  src={Close}
                />
                {(searchedResults.length > 0 ||
                  searchedCategories.length > 0) &&
                searchText != "" &&
                !inApi ? (
                  <div className="byCodeContainer">
                    {searchedResults.length > 0 && <p>With Code</p>}
                    <div>
                      {searchedResults.length > 0 &&
                        searchedResults.map((item) => (
                          <ToomarCart value={item} />
                        ))}
                    </div>
                    {searchedCategories.length > 0 && <p>With Category</p>}
                    <div>
                      {searchedCategories.length > 0 &&
                        searchedCategories.map((item) => (
                          <div>
                            <p
                              onClick={() => {
                                navigate(categoryUrl(item));
                              }}
                            >
                              {item.name}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div onClick={() => setOpenSearch(true)}>
                <img src={Search} alt={"search"} />
              </div>
            )}
            <Link to={"/favorite/"}>
              <div>
                {props.favoriteProducts?.length > 0 && (
                  <p style={{ fontFamily: "antialiased" }}>
                    {props.favoriteProducts.length}
                  </p>
                )}
                <img
                  src={require("../../assets/non_favorite.png")}
                  alt={"bag"}
                />
              </div>
            </Link>
            {props.buyingProducts.length > 0 ? (
              <Link to={"/cart/"}>
                <div>
                  <p style={{ fontFamily: "antialiased" }}>
                    {props.buyingProducts.length}
                  </p>
                  <img src={Bag} alt={"bag"} />
                </div>
              </Link>
            ) : (
              <div onClick={() => setCartModal(true)}>
                <img src={Bag} alt={"bag"} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{ direction: "ltr" }}
        ref={mobileMenuRef}
        className={"menuContainerMobile"}
      >
        <div>
          <Link to={"/"}>
            <img src={Logo} alt={"logo"} />
          </Link>
          <div>
            <div
              style={{ position: "relative" }}
              onClick={() => setShowLanguageBox(!showLanguageBox)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "4px 0px",
                  border: "1px solid black",
                  borderRadius: "14px",
                  direction: "ltr",
                  minWidth: "130px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={require("../../assets/usa.png")}
                  alt="usa"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
                <p
                  style={{
                    fontSize: "14px",
                    color: "black",
                    margin: "0px 0px 0px 6px",
                  }}
                >
                  EN
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.7)",
                    margin: "0px 0px 0px 2px",
                  }}
                >
                  {"(USD)"}
                </p>
              </div>
              {showLanguageBox && (
                <div
                  style={{ position: "absolute", bottom: "-40px", left: "0px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "4px 0px",
                      border: "1px solid black",
                      borderRadius: "14px",
                      marginRight: "40px",
                      direction: "ltr",
                      minWidth: "130px",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open("https://toomar.co")}
                  >
                    <img
                      src={require("../../assets/iran.png")}
                      alt="iran"
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    <p
                      style={{
                        fontSize: "14px",
                        color: "black",
                        margin: "0px 0px 0px 6px",
                      }}
                    >
                      Persian
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "rgba(0, 0, 0, 0.7)",
                        margin: "0px 0px 0px 2px",
                      }}
                    >
                      {"(RIAL)"}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <a
              style={{ display: "flex" }}
              href={"http://t.me/toomar_aroosicard"}
              target={"_blank"}
            >
              <img src={Telegram} alt={"telegram"} />
            </a>
            <a
              href={"http://wa.me/989057837887"}
              style={{ marginRight: "10px", display: "flex" }}
              target={"_blank"}
            >
              <img src={Whatsapp} alt={"whatsapp"} />
            </a>
            <a
              style={{ display: "flex" }}
              href={"https://www.instagram.com/toomar_aroosicard/"}
              target={"_blank"}
            >
              <img src={Instagram} alt={"instagram"} />
            </a>
          </div>
        </div>
        <div>
          <img
            onClick={() => {
              setOpenMenu(!openMenu);
              setShowCategories(false);
            }}
            src={Hamburger}
            alt={"hamburger"}
          />
          {openMenu ? (
            <div className={"toggleMenuContainer"}>
              {/* <div> */}
              {/* <img src={Filter} alt={'filter'}/> */}
              <div>
                <span onClick={() => setShowCategories(!showCategories)}>
                  Category
                </span>
                {showCategories &&
                  allCategories.map((item, index) => (
                    <button
                      onClick={() => {
                        navigate(categoryUrl(item));
                      }}
                    >
                      {item.icon ? (
                        <img
                          style={{ width: 24, height: 24 }}
                          src={`https://toomar.co${item.icon}`}
                        />
                      ) : (
                        <img
                          src={
                            index == 0
                              ? WeddingCardBlack
                              : index == 1
                              ? WeddingDigitalCard
                              : index == 2
                              ? WeddingGift
                              : index == 3
                              ? Office
                              : index == 4
                              ? MemorialBoard
                              : SpecialGift
                          }
                        />
                      )}
                      <span>{item.name}</span>
                    </button>
                  ))}
              </div>
              {/* </div> */}
              <Link to={"/gallery/"}>
                <div>
                  <span>Wedding Products Gallery</span>
                </div>
              </Link>
              {/* <a href={"https://tarhim.toomar.co/"}>
                            <div>
                                <span>
                                    ورود به سایت ترحیم
                                </span>
                            </div>
                        </a> */}
              <Link to={"/magazine/"}>
                <span>Toomar Mag</span>
              </Link>
              <Link to={"/about-us/"}>
                <span>About us</span>
              </Link>
              <Link to={"/contact-us/"}>
                <span>Contact us</span>
              </Link>
              <Link to="/followup-order/">
                <span>Order Tracking</span>
              </Link>
            </div>
          ) : null}
          <div>
            {openSearch ? (
              <div className="searchContainer">
                <input
                  placeholder="enter product code or desired category "
                  value={searchText}
                  onChange={(e) => handleGetResults(e)}
                />
                <img
                  onClick={() => {
                    setSearchResults([]);
                    setSearchText("");
                    setOpenSearch(false);
                  }}
                  src={Close}
                />
                {(searchedResults.length > 0 ||
                  searchedCategories.length > 0) &&
                searchText != "" &&
                !inApi ? (
                  <div className="byCodeContainer">
                    {searchedResults.length > 0 && <p>With code</p>}
                    <div>
                      {searchedResults.length > 0 &&
                        searchedResults.map((item) => (
                          <ToomarCart value={item} />
                        ))}
                    </div>
                    {searchedCategories.length > 0 && <p>With category</p>}
                    <div>
                      {searchedCategories.length > 0 &&
                        searchedCategories.map((item) => (
                          <div>
                            <p
                              onClick={() => {
                                navigate(categoryUrl(item));
                              }}
                            >
                              {item.name}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <img
                onClick={() => setOpenSearch(true)}
                src={Search}
                alt={"search"}
              />
            )}
            <Link to={"/favorite/"}>
              <div>
                {props.favoriteProducts?.length > 0 && (
                  <p style={{ fontFamily: "antialiased" }}>
                    {props.favoriteProducts.length}
                  </p>
                )}
                <img
                  src={require("../../assets/non_favorite.png")}
                  alt={"bag"}
                />
              </div>
            </Link>
            {props.buyingProducts.length > 0 ? (
              <Link to={"/cart/"}>
                <div>
                  <p style={{ fontFamily: "antialiased" }}>
                    {props.buyingProducts.length}
                  </p>
                  <img src={Bag} alt={"bag"} />
                </div>
              </Link>
            ) : (
              <div onClick={() => setCartModal(true)}>
                <img src={Bag} alt={"bag"} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  buyingProducts: state.counter.buyingProducts,
  favoriteProducts: state.counter.favoriteProducts,
});

export default connect(mapStateToProps, {
  getSearchResults,
  getCategoriesTree,
})(Menu);
