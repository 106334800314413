import React from "react";
import ToomarContainer from "../containers/ToomarContainer";
import Title from "../Utils/Title";
import "./ContactUs.scss";
import FollowUsInSocials from "../kit/FollowUsInSocials";
import Whatsapp from "../../assets/greenWhatsapp.svg";
import Telegram from "../../assets/blueTelegram.svg";
import Mobile from "../../assets/blueMobile.svg";

function ContactUs() {
  return (
    <ToomarContainer>
      <Title goldText={"Contact "} blackText={"us"} />
      <div className={"contactUsContainer"}>
        <p>
          Tomar is at your service as the largest online store of wedding
          invitations and wedding items.
        </p>
        <p>
          ATTENTION:{" "}
          <span>
            Please read the text below and the{" "}
            <a
              href="/repetitive-questions"
              target="_blank"
              style={{ color: "#C69C61", textDecoration: "underline" }}
            >
              FAQ
            </a>{" "}
            section before calling
          </span>
        </p>
        <p>
          The time for preparing and sending the products is{" "}
          <span style={{ fontFamily: "sans-serif" }}>20</span> to{" "}
          <span style={{ fontFamily: "sans-serif" }}>30</span> working days from
          the moment of placing the order
        </p>
        <p>
          In orders for invitations and printed and digital products, before
          printing and finalizing the design, a sample of the designed text (in
          Telegram, WhatsApp or other messengers) will be sent to you for
          confirmation.
        </p>
        <p style={{ color: "red", textAlign: "center", fontSize: "12px" }}>
          All the mentioned prices are the final prices of the products
          (invitation cards including the cost of printing and designing, etc.)
          and the prices are up-to- date.
        </p>
        <p>Advice and guidance</p>
        <div>
          <p>
            For advice and guidance on placing an order, contact the following
            numbers on WhatsApp:
            <span style={{ color: "red", fontSize: "12px" }}>
              (it is recommended to refer to the{" "}
              <a
                href="/repetitive-questions"
                target="_blank"
                style={{ color: "black", textDecoration: "underline" }}
              >
                FAQ
              </a>{" "}
              section before calling
            </span>
          </p>
          <div>
            <div>
              <img src={Mobile} alt={"mobile"} />
              <p>Phone numbers</p>
              <div>
                <p style={{ fontFamily: "sans-serif" }}>
                  +989355030079 - +989054816441
                </p>
              </div>
            </div>
          </div>
          <div>
            <a
              href={"http://wa.me/989054816441"}
              target={"_blank"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={Whatsapp} alt={"whatsapp"} />
              <p
                style={{
                  color: "black",
                  fontSize: "12px",
                  margin: "4px 0px 0px",
                }}
              >
                Whatsapp
              </p>
            </a>
            <a
              href={"http://t.me/toomar_aroosicards"}
              target={"_blank"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={Telegram}
                alt={"telegram"}
                style={{ width: "30px", height: "30px" }}
              />
              <p
                style={{
                  color: "black",
                  fontSize: "12px",
                  margin: "4px 0px 0px",
                }}
              >
                Telegram
              </p>
            </a>
          </div>
        </div>
        <p>Contact for tracking orders</p>
        <p>
          After placing your order, you will receive an SMS with the order
          tracking link, which you can click on to find out about the status of
          your order.
        </p>
        <div>
          <div>
            <img src={Mobile} alt={"mobile"} />
            <p>Phone number</p>
            <p style={{ fontFamily: "sans-serif" }}>+989339721035</p>
          </div>
          <div>
            <a
              href={"http://wa.me/989057837887"}
              target={"_blank"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={Whatsapp} alt={"whatsapp"} />
              <p
                style={{
                  color: "black",
                  fontSize: "12px",
                  margin: "4px 0px 0px",
                }}
              >
                Whatsapp for tracking orders
              </p>
            </a>
            <a
              href={"http://t.me/+989057837887"}
              target={"_blank"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={Telegram} alt={"telegram"} />
              <p
                style={{
                  color: "black",
                  fontSize: "12px",
                  margin: "4px 0px 0px",
                }}
              >
                Telegram for tracking orders
              </p>
            </a>
          </div>
        </div>

        {/* <p>
                    ادرس دفتر فروش برای مراجعه حضوری(با هماهنگی قبلی)
                </p>
                <p>
                    تهران ، خیابان جمهوری ، بین خیابان ملت و میدان بهارستان  ، پلاک ۶۰ طبقه اول ، انتهای راه رو
                </p> */}
        <p>Email address</p>
        <p>info@toomar.co</p>
        <FollowUsInSocials />
      </div>
    </ToomarContainer>
  );
}

export default ContactUs;
