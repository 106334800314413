import React, { useEffect, useState } from 'react';
import ToomarContainer from '../containers/ToomarContainer';
import './FollowUpLastOrder.scss';
import WeddingCard from '../../assets/weddingCard.svg';
import { setBuyingProduct, setFinishedProductDetails, verifyBuyResult } from '../../redux/actions';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

function FollowUpLastOrder({...props}) {
    const search = useLocation().search;
    const status = new URLSearchParams(search).get('status');
    const params = useParams();
    const [apiData, setApiData] = useState(null);
    useEffect(() => {
        props.verifyBuyResult(params.id, (data) => {
            if(data.data.status == 'success') {
                props.setBuyingProduct([]);
                props.setFinishedProductDetails([]);
            }
            setApiData(data.data)
        })
    }, {})

    return (
        <ToomarContainer>
            <div className={'followUpLastOrderContainer'}>
                <div>
                    <img src={WeddingCard} alt={'state'}/>
                    <p>
                        {apiData != null && apiData.status == 'success' ?
                        'خرید شما با موفقیت ثبت شد' : apiData != null && apiData.status == 'failed' ? 'خرید شما متاسفانه انجام نشد' : null}
                    </p>
                    {apiData != null && apiData.status == 'success' ?
                    <>
                        <p>
                            شماره پیگیری سفارش شما
                        </p>
                        <p>
                            {apiData.orderId}
                        </p>
                    </>
                    : null}
                    {/* <AllFeatures/> */}
                </div>
            </div>
        </ToomarContainer>
    )
}

export default connect(null, {verifyBuyResult, setBuyingProduct, setFinishedProductDetails})(FollowUpLastOrder);