import React, { useEffect, useState } from 'react';
import './Magazine.scss';
import ToomarContainer from '../containers/ToomarContainer';
import { useParams } from 'react-router-dom';
import { getOneBlog } from '../../redux/actions';
import { connect } from 'react-redux';
// import {Helmet} from "react-helmet";

function MagazineDetails({...props}) {
    const [blogDetails, setBlogDetails] = useState({});
    const params = useParams();

    useEffect(() => {
        props.getOneBlog(params.id, (data) => setBlogDetails(data.data))
    }, {})

    return (
        <ToomarContainer>
            {/*<Helmet>*/}
            {/*    <title>{blogDetails?.title}مجله طومار | </title>*/}
            {/*    <meta name="title" content="گالری محصولات طومار | مرجع اطلاع از قیمت و فروش کارت و اقلام عروسی" />*/}
            {/*    <meta name="description" content="بالای ۱۰۰۰ مدل کارت های عروسی چاپی در هفت دسته بندی مختلف با قیمت نهایی ، گالری کارت های عروسی دیجیتال ، گیفت عقد ، دفاتر بله برون و جهیزیه ، تابلو های یاد بود مراسم ، ست های بله برون و..." />*/}
            {/*    <meta property="og:title" content={blogDetails?.title} />*/}
            {/*    <meta property="og:url" content={""} />*/}
            {/*</Helmet>*/}
            <div className={'magazineDetailsContainer'}>
                <img src={blogDetails?.image} alt={'advertisement'}/>
                <div>
                    <p>
                        {blogDetails?.title}
                    </p>
                        {/* <p>
                            تاییدیه قبل از چاپ چیست ؟ ما به منظور بالا بردن کیفیت محصول نهایی و رضایت شما زوج ها و کاربران عزیز از شما تاییدیه میخواهیم به این صورت که بعد از ثبت سفارش کارت عروسیتان و طی کردن مراحل سفارش و پرداخت ، تیم پشتیبانی در تلگرام و یا واتس اپ شماره ای که ثبت کرده اید به شما پیام میدهند ، و متن طراحی شده دعوتنامه شمارا برایتان ارسال میکنند ؛ تا اگر نیاز به هرگونه تغییر یا اصلاحی داشت تیم طراحی برایتان انجام دهند که متن طراحی شده کارت دعوت شما دقیقا چیزی که میخواهید شود و پس از تایید شما وارد مرحله چاپ و کلیشه سازی گردد . بدون تایید نهایی شما زوج های عزیز ، تیم طومار هیچ گونه اقدامی برای چاپ کارت ها نخواهد کرد و تنها با تایید نهایی شما متن کارت دعوتتان چاپ میشود. 
                        </p> */}
                    {/* <img src={Test1} alt={'کارت عروسی'}/> */}
                </div>
                <div dangerouslySetInnerHTML={{ __html: blogDetails?.content }} />
                {/* <div>
                    <div>
                        <p>
                            جذاب ترین کارت های عروسی دنیا
                        </p>
                        <p>
                            تاییدیه قبل از چاپ چیست ؟ ما به منظور بالا بردن کیفیت محصول نهایی و رضایت شما زوج ها و کاربران عزیز از شما تاییدیه میخواهیم به این صورت که بعد از ثبت سفارش کارت عروسیتان و طی کردن مراحل سفارش و پرداخت ، تیم پشتیبانی در تلگرام و یا واتس اپ شماره ای که ثبت کرده اید به شما پیام میدهند ، و متن طراحی شده دعوتنامه شمارا برایتان ارسال میکنند ؛ تا اگر نیاز به هرگونه تغییر یا اصلاحی داشت تیم طراحی برایتان انجام دهند که متن طراحی شده کارت دعوت شما دقیقا چیزی که میخواهید شود و پس از تایید شما وارد مرحله چاپ و کلیشه سازی گردد . بدون تایید نهایی شما زوج های عزیز ، تیم طومار هیچ گونه اقدامی برای چاپ کارت ها نخواهد کرد و تنها با تایید نهایی شما متن کارت دعوتتان چاپ میشود. 
                        </p>
                    </div>
                    <img src={Test1} alt={'کارت عروسی'}/>
                </div>
                <div>
                    <div>
                        <p>
                            جذاب ترین کارت های عروسی دنیا
                        </p>
                        <p>
                            تاییدیه قبل از چاپ چیست ؟ ما به منظور بالا بردن کیفیت محصول نهایی و رضایت شما زوج ها و کاربران عزیز از شما تاییدیه میخواهیم به این صورت که بعد از ثبت سفارش کارت عروسیتان و طی کردن مراحل سفارش و پرداخت ، تیم پشتیبانی در تلگرام و یا واتس اپ شماره ای که ثبت کرده اید به شما پیام میدهند ، و متن طراحی شده دعوتنامه شمارا برایتان ارسال میکنند ؛ تا اگر نیاز به هرگونه تغییر یا اصلاحی داشت تیم طراحی برایتان انجام دهند که متن طراحی شده کارت دعوت شما دقیقا چیزی که میخواهید شود و پس از تایید شما وارد مرحله چاپ و کلیشه سازی گردد . بدون تایید نهایی شما زوج های عزیز ، تیم طومار هیچ گونه اقدامی برای چاپ کارت ها نخواهد کرد و تنها با تایید نهایی شما متن کارت دعوتتان چاپ میشود. 
                        </p>
                    </div>
                    <img src={Test1} alt={'کارت عروسی'}/>
                </div>
                <div>
                    <div>
                        <p>
                            جذاب ترین کارت های عروسی دنیا
                        </p>
                        <p>
                            تاییدیه قبل از چاپ چیست ؟ ما به منظور بالا بردن کیفیت محصول نهایی و رضایت شما زوج ها و کاربران عزیز از شما تاییدیه میخواهیم به این صورت که بعد از ثبت سفارش کارت عروسیتان و طی کردن مراحل سفارش و پرداخت ، تیم پشتیبانی در تلگرام و یا واتس اپ شماره ای که ثبت کرده اید به شما پیام میدهند ، و متن طراحی شده دعوتنامه شمارا برایتان ارسال میکنند ؛ تا اگر نیاز به هرگونه تغییر یا اصلاحی داشت تیم طراحی برایتان انجام دهند که متن طراحی شده کارت دعوت شما دقیقا چیزی که میخواهید شود و پس از تایید شما وارد مرحله چاپ و کلیشه سازی گردد . بدون تایید نهایی شما زوج های عزیز ، تیم طومار هیچ گونه اقدامی برای چاپ کارت ها نخواهد کرد و تنها با تایید نهایی شما متن کارت دعوتتان چاپ میشود. 
                            تاییدیه قبل از چاپ چیست ؟ ما به منظور بالا بردن کیفیت محصول نهایی و رضایت شما زوج ها و کاربران عزیز از شما تاییدیه میخواهیم به این صورت که بعد از ثبت سفارش کارت عروسیتان و طی کردن مراحل سفارش و پرداخت ، تیم پشتیبانی در تلگرام و یا واتس اپ شماره ای که ثبت کرده اید به شما پیام میدهند ، و متن طراحی شده دعوتنامه شمارا برایتان ارسال میکنند ؛ تا اگر نیاز به هرگونه تغییر یا اصلاحی داشت تیم طراحی برایتان انجام دهند که متن طراحی شده کارت دعوت شما دقیقا چیزی که میخواهید شود و پس از تایید شما وارد مرحله چاپ و کلیشه سازی گردد . بدون تایید نهایی شما زوج های عزیز ، تیم طومار هیچ گونه اقدامی برای چاپ کارت ها نخواهد کرد و تنها با تایید نهایی شما متن کارت دعوتتان چاپ میشود. 

                        </p>
                    </div>
                    <img src={Test1} alt={'کارت عروسی'}/>
                </div>
                <div>
                    <div>
                        <p>
                            جذاب ترین کارت های عروسی دنیا
                        </p>
                        <p>
                            تاییدیه قبل از چاپ چیست ؟ ما به منظور بالا بردن کیفیت محصول نهایی و رضایت شما زوج ها و کاربران عزیز از شما تاییدیه میخواهیم به این صورت که بعد از ثبت سفارش کارت عروسیتان و طی کردن مراحل سفارش و پرداخت ، تیم پشتیبانی در تلگرام و یا واتس اپ شماره ای که ثبت کرده اید به شما پیام میدهند ، و متن طراحی شده دعوتنامه شمارا برایتان ارسال میکنند ؛ تا اگر نیاز به هرگونه تغییر یا اصلاحی داشت تیم طراحی برایتان انجام دهند که متن طراحی شده کارت دعوت شما دقیقا چیزی که میخواهید شود و پس از تایید شما وارد مرحله چاپ و کلیشه سازی گردد . بدون تایید نهایی شما زوج های عزیز ، تیم طومار هیچ گونه اقدامی برای چاپ کارت ها نخواهد کرد و تنها با تایید نهایی شما متن کارت دعوتتان چاپ میشود. 
                        </p>
                    </div>
                    <img src={Test1} alt={'کارت عروسی'}/>
                </div>
                <div>
                    <div>
                        <p>
                            جذاب ترین کارت های عروسی دنیا
                        </p>
                        <p>
                            تاییدیه قبل از چاپ چیست ؟ ما به منظور بالا بردن کیفیت محصول نهایی و رضایت شما زوج ها و کاربران عزیز از شما تاییدیه میخواهیم به این صورت که بعد از ثبت سفارش کارت عروسیتان و طی کردن مراحل سفارش و پرداخت ، تیم پشتیبانی در تلگرام و یا واتس اپ شماره ای که ثبت کرده اید به شما پیام میدهند ، و متن طراحی شده دعوتنامه شمارا برایتان ارسال میکنند ؛ تا اگر نیاز به هرگونه تغییر یا اصلاحی داشت تیم طراحی برایتان انجام دهند که متن طراحی شده کارت دعوت شما دقیقا چیزی که میخواهید شود و پس از تایید شما وارد مرحله چاپ و کلیشه سازی گردد . بدون تایید نهایی شما زوج های عزیز ، تیم طومار هیچ گونه اقدامی برای چاپ کارت ها نخواهد کرد و تنها با تایید نهایی شما متن کارت دعوتتان چاپ میشود. 
                        </p>
                    </div>
                    <img src={Test1} alt={'کارت عروسی'}/>
                </div> */}
            </div>
        </ToomarContainer>
    )
}

export default connect(null, { getOneBlog })(MagazineDetails);