export const flatten = (children, extractChildren, level, parent) =>
  Array.prototype.concat.apply(
    children.map((x) => ({ ...x, level: level || 1, parent: parent || null })),
    children.map((x) =>
      flatten(extractChildren(x) || [], extractChildren, (level || 1) + 1, x.id)
    )
  );

export const extractChildren = (x) => x.children;

export const flat = (root) =>
  flatten(extractChildren(root), extractChildren).map(
    (x) => delete x.children && x
  );
