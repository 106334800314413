import React from 'react';
import Footer from '../footer/Footer';
import Menu from '../menu/Menu';

function ToomarContainer({children, ...props}) {
    return (
        <div>
            <Menu/>
            {children}
            <Footer/>
        </div>
    )
}


export default ToomarContainer;