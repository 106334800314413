import React from "react";
import './Utils.scss';
import ToomarUnderline from '../../assets/toomarUnderline.svg';

function Title({blackText, goldText}) {
    return (
        <div className={'titleContainer'}>
            <div>
                <span>
                    {goldText}
                </span>
                <span>
                    {blackText}
                </span>
            </div>
            <img src={ToomarUnderline} alt={'toomarUnderline'}/>
        </div>
    )
}

export default Title;