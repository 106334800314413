import React, { useEffect, useState } from "react";
import ArrowBottom from "../../assets/arrowBlackBottom.svg";
import ArrowTop from "../../assets/arrowTopBlack.svg";
import "./Gallery.scss";
import { useNavigate } from "react-router-dom";
import { categoryUrl } from "../../lib/urls";

const isEqual = (a, b) => a?.toString() === b?.toString();

function Categories({ data, currentCategoryId, goToGalleryPage, closeModal }) {
  const navigate = useNavigate();
  const [openChildren, setOpenChildren] = useState(false);

  useEffect(() => {
    if (
      isEqual(data.id, currentCategoryId) ||
      data.children?.filter(
        (item) =>
          isEqual(item.id, currentCategoryId) ||
          item.children?.filter((t) => isEqual(t.id, currentCategoryId)).length
      ).length
    ) {
      setOpenChildren(true);
    } else {
      setOpenChildren(false);
    }
  }, [data, currentCategoryId]);

  const handleChild = (item) => {
    if(item.children?.length === 0) {
      closeModal && closeModal();
    }
  } 

  return (
    <>
      <div
        className="parent"
        onClick={() => {
          navigate(`/gallery/${data.id}/${data.name}`);
          handleChild(data)
        }}
      >
        <p style={isEqual(currentCategoryId, data.id) ? { color: "#D45A07" } : null}>
          {data.name}
        </p>
        {!!data.children?.length && (
          <img src={openChildren ? ArrowTop : ArrowBottom} alt="" />
        )}
      </div>
      {openChildren
        ? data.children.map((item) => (
            <div key={item.id} className="childrenGallery">
              {/* <p>.</p> */}
              {goToGalleryPage == true ? (
                <a href={`/gallery/${item.id}`}>{`. ${item.name.replaceAll(
                  "/",
                  "|"
                )}`}</a>
              ) : (
                <>
                  <p
                    onClick={() => {
                      navigate(categoryUrl(item),
                      handleChild(item)
                      );
                    }}
                    style={isEqual(currentCategoryId, item.id) ? { color: "#D45A07" } : null}
                  >
                    {`. ${item.name}`}
                  </p>
                  {isEqual(currentCategoryId, item.id) ||
                  item.children?.filter((t) => isEqual(currentCategoryId, t.id)).length ? (
                    <div>
                      {item.children.map((item) => (
                        <p
                          key={item.id}
                          onClick={() => {
                            navigate(categoryUrl(item)
                            );
                            closeModal && closeModal();
                          }}
                          style={
                            isEqual(currentCategoryId, item.id) ? { color: "#D45A07" } : null
                          }
                        >
                          {item.name}
                        </p>
                      ))}
                    </div>
                  ) : null}
                </>
              )}
            </div>
          ))
        : null}
    </>
  );
}

export default Categories;
