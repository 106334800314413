import React, { useState } from 'react';
import ArrowBottom from '../../assets/arrowBottom.svg';
import './Utils.scss';

function CollapseBar({ question, answer}) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={'collapseBarContainer'}>
            <div>
                <span>
                    {question}
                </span>
                <img onClick={() => setIsOpen(!isOpen)} src={ArrowBottom} alt={'arrow'}/>
            </div>
            <span className={isOpen ? 'responseOpen' : 'responseClose'}>
                {answer}
            </span>
        </div>
    )
}

export default CollapseBar;