import moment from "jalali-moment";
import React from "react";
// Calculating the time difference
// of two dates
export const differenceInTime = (date1, date2) =>
  date2.getTime() - date1.getTime();

// Calculating the no. of days between
// two dates
export const differenceInDays = (date1, date2) =>
  Math.floor(differenceInTime(date1, date2) / (1000 * 3600 * 24));

const days = [
  "Sunday", //0
  "Monday", //1
  "Tuesday", //2
  "Wednesday", //3
  "Thursday", //4
  "Friday", //5
  "Saturday", //6
];

export const dayName = (date) => days[new Date(date).getDay()];

//hamoon lahze mire pardazesh, 48 saate kari mire rooye tarrahi, 72 saat baad baste bandi va ersal

export const handleDifferenceForStatus = (
  orderDate,
  todayDate,
  dayNameOrder,
  dayNameNow,
  days
) => {
  let localDays = days;
  for (let i = 0; i < persianHolidays.length; i++) {
    let dateCheck = moment
      .from(persianHolidays[i].date, "fa", "YYYY/MM/DD")
      .format("DD/MM/YYYY");
    let d1 = orderDate.split("/");
    let d2 = todayDate.split("/");
    let c = dateCheck.split("/");

    let from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
    let to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
    let check = new Date(c[2], parseInt(c[1]) - 1, c[0]);
    if (check > from && check < to) {
      localDays = localDays - 1;
    }
  }
  if (days >= 7) return 3;
  else if (dayNameOrder === 6 && dayNameNow === 1 && days >= 2) return 2;
  else if (dayNameOrder === 0 && dayNameNow === 2 && days >= 2) return 2;
  else if (dayNameOrder === 1 && dayNameNow === 3 && days >= 2) return 2;
  else if (dayNameOrder === 2 && dayNameNow === 4 && days >= 2) return 2;
  else if (dayNameOrder === 3 && dayNameNow === 6 && days >= 2) return 2;
  else if (dayNameOrder === 4 && dayNameNow === 0 && days >= 2) return 2;
  else if (dayNameOrder === 5 && dayNameNow === 1 && days >= 2) return 2;
  else return 1;
};

const persianHolidays = [
  {
    date: "1403/1/1",
    description: "جشن نوروز/جشن سال نو",
  },
  {
    date: "1403/1/2",
    description: "عیدنوروز",
  },
  {
    date: "1403/1/3",
    description: "عیدنوروز",
  },
  {
    date: "1403/1/4",
    description: "عیدنوروز",
  },
  {
    date: "1403/1/12",
    description: "روز جمهوری اسلامی",
  },
  {
    date: "1403/1/13",
    description: "جشن سیزده به در",
  },
  {
    date: "1403/1/13",
    description: "شهادت حضرت علی علیه السلام [ ٢١ رمضان ]",
  },
  {
    date: "1403/1/22",
    description: "عید سعید فطر [ ١ شوال ]",
  },
  {
    date: "1403/1/23",
    description: "تعطیل به مناسبت عید سعید فطر [ ٢ شوال ]",
  },
  {
    date: "1403/2/15",
    description: "شهادت امام جعفر صادق علیه السلام [ ٢٥ شوال ]",
  },
  {
    date: "1403/3/14",
    description: "رحلت حضرت امام خمینی",
  },
  {
    date: "1403/3/15",
    description: "قیام 15 خرداد",
  },
  {
    date: "1403/3/28",
    description: "عید سعید قربان [ ١٠ ذوالحجه ]",
  },
  {
    date: "1403/4/5",
    description: "عید سعید غدیر خم [ ١٨ ذوالحجه ]",
  },
  {
    date: "1403/4/25",
    description: "تاسوعای حسینی [ ٩ محرم ]",
  },
  {
    date: "1403/4/26",
    description: "عاشورای حسینی [ ١٠ محرم ]",
  },
  {
    date: "1403/6/4",
    description: "اربعین حسینی [ ٢٠ صفر ]",
  },
  {
    date: "1403/6/12",
    description: "رحلت رسول اکرم؛شهادت امام حسن مجتبی علیه السلام [ ٢٨ صفر ]",
  },
  {
    date: "1403/6/14",
    description: "شهادت امام رضا علیه السلام [ ٣٠ صفر ]",
  },
  {
    date: "1403/6/22",
    description: "شهادت امام حسن عسکری علیه السلام [ ٨ ربيع الاول ]",
  },
  {
    date: "1403/6/31",
    description:
      "میلاد رسول اکرم و امام جعفر صادق علیه السلام [ ١٧ ربيع الاول ]",
  },
  {
    date: "1403/9/15",
    description: "شهادت حضرت فاطمه زهرا سلام الله علیها [ ٣ جمادي الثانيه ]",
  },
  {
    date: "1403/10/25",
    description: "ولادت امام علی علیه السلام و روز پدر [ ١٣ رجب ]",
  },
  {
    date: "1403/11/9",
    description: "مبعث رسول اکرم (ص) [ ٢٧ رجب ]",
  },
  {
    date: "1403/11/22",
    description: "پیروزی انقلاب اسلامی",
  },
  {
    date: "1403/11/26",
    description:
      "ولادت حضرت قائم عجل الله تعالی فرجه و جشن نیمه شعبان [ ١٥ شعبان ]",
  },
  {
    date: "1403/12/29",
    description: "روز ملی شدن صنعت نفت ایران",
  },
  {
    date: "1403/12/30",
    description: "آخرین روز سال",
  },
];
